// External dependencies
import React, { useState } from "react";

// Material-UI components
import { TableRow, TableCell, IconButton, Collapse, Box } from "@mui/material";

// Internal dependencies
import AlertDialog from "components/AlertDialog";
import { AlertsProps } from "components/Alerts/data/Alerts.type";
import FormDialog from "components/Dialog/modal-popup";
import SnackBar from "components/SnackBar";
import { ConfirmationMessages, UserRoles } from "constants/constant";
import {
  _deleteUserRole,
  _updateUser,
} from "middlewares/ManageUsers/manageusers";
import AssignOrganization from "./AssignOrganization";
import { userRoleModel } from "./data/userRoleModel";
import { userRoles } from "./data/userRoles";

// MUI icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import { OrganizationMapping } from "pages/HomePage/data/HomePage.type";
import { useAppState } from "context/appState.context";
import { useParams } from "react-router-dom";

// Function to get role name by id
const getRoleNameById = (roleId?: string | number | undefined) => {
  const role = userRoles.find((r) => r.value === roleId!.toString());
  return role ? role.label : "";
};
type RowProps = {
  row: userRoleModel;
  getUserRoles: () => void;
  handleEditClick: () => void;
  organizations: Array<OrganizationMapping>;
};

/**
 * Row component
 * 
 * This component renders a table row with user information and actions to edit, delete, 
 * and assign organizations to the user. It also supports expanding to show assigned organizations.
 * 
 * @param {RowProps} props - The props for the component.
 * @param {userRoleModel} props.row - The data of the user role.
 * @param {Function} props.getUserRoles - Function to fetch user roles.
 * @param {Function} props.handleEditClick - Function to handle edit action.
 * @param {Array<OrganizationMapping>} props.organizations - List of organizations to display.
 * @returns {JSX.Element} A component for rendering a row with user role information and actions.
 */
const Row: React.FC<Readonly<RowProps>> = (props) => {
  //State variables
  const [toggle, setToggle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalForOrg, setOpenModalForOrg] = useState(false);
  const [userRoleId, setUserRoleId] = useState(0);
  const [userName, setUserName] = useState("");
  const [orgId, setOrgId] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [orgNames, setOrgNames] = useState<
    Array<{ orgId: number; orgName: string }>
  >([]);
  const [success, setSuccess] = useState<AlertsProps>({
    show: false,
    severity: "info",
    message: "",
  });

  const { userRole } = useAppState();
  const { OrganizationId } = useParams();
  const isSuperAdmin = userRole === UserRoles.SuperAdmin || userRole === UserRoles.Admin;


  // Event handlers
  /**
   * Closes the modal dialog.
   */
  const handleClose = () => {
    setOpenModal(false);
  };

  /**
   * Closes the modal dialog for organization assignment.
   */
  const handleCloseForOrg = () => {
    setOpenModalForOrg(false);
  };

    /**
   * Opens the modal dialog.
   */
  const openDialog = () => {
    setOpenModal(true);
  };
  /**
   * Opens the modal dialog for organization assignment.
   */
  const openDialogForOrg = () => {
    setOpenModalForOrg(true);
  };

  /**
   * Closes the snackbar notification.
   * 
   * @param {AlertsProps} state - The state of the snackbar.
   */
  const handleCloseSnackBar = (state: AlertsProps) => {
    setSuccess(state);
  };

   /**
   * Hides the dialog.
   */
  const hideDialogHandler = () => setDialogOpen(false);

  /**
   * Deletes the user role.
   */
  const deleteUserRole = async () => {
    try {
      if (userRoleId !== undefined) {
        const res = await _deleteUserRole(userRoleId,Number(OrganizationId));
        if (res) {
          props.getUserRoles();
        }
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

   /**
   * Confirms the deletion of the user role.
   */
  const handleConfirm = () => {
    deleteUserRole();
    handleClose();
    handleCloseSnackBar({
      show: true,
      severity: "success",
      message: "Deleted Successfully !",
    });
  };

  /**
   * Confirms the organization assignment update.
   */
  const handleOrgConfirm = () => {
    updateUser();
    handleClose();
    handleCloseSnackBar({
      show: true,
      severity: "success",
      message: "Deleted Successfully !",
    });
  };

  /**
   * Updates the user by removing the assigned organization.
   */
  const updateUser = async () => {
    try {
      const { row } = props;
      const updatedUserRoleModel = { ...row };
      const orgIdsToRemove = [orgId];
      if (updatedUserRoleModel.organizationMapping) {
        const orgIds = updatedUserRoleModel.organizationMapping.split(",");
        orgIdsToRemove.forEach((id) => {
          const index = orgIds.indexOf(id.toString());
          if (index !== -1) {
            orgIds.splice(index, 1);
          }
        });
        updatedUserRoleModel.organizationMapping = orgIds.join(",");
      }
      const res = await _updateUser(updatedUserRoleModel);
      if (res) {
        setToggle(!toggle);
        props.getUserRoles();
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

   /**
   * Handles the click event to delete a user role.
   * 
   * @param {number} userRoleId - The ID of the user role.
   * @param {string} userName - The name of the user.
   */
  const handleDeleteClick = (userRoleId: number, userName: string) => {
    openDialog();
    setUserRoleId(userRoleId);
    setUserName(userName);
  };

  /**
   * Handles the click event to delete an assigned organization.
   * 
   * @param {number} userRoleId - The ID of the user role.
   * @param {string} userName - The name of the user.
   * @param {number} orgId - The ID of the organization.
   */
  const handleOrgDeleteClick = (
    userRoleId: number,
    userName: string,
    orgId: number
  ) => {
    openDialogForOrg();
    setUserRoleId(userRoleId);
    setUserName(userName);
    setOrgId(orgId);
  };

  /**
   * Handles the click event to assign organizations to a user role.
   * 
   * @param {number} userRoleId - The ID of the user role.
   */
  const handleAssignOrganizationClick = (userRoleId: number) => {
    setDialogOpen(true);
    setUserRoleId(userRoleId);
  };

  /**
   * Handles the click event to expand or collapse the row to show assigned organizations.
   * 
   * @param {userRoleModel} userRoleModel - The user role model.
   */
  const handleExpand = async (userRoleModel: userRoleModel) => {
    try {
      const { organizations } = props;
      const orgMapping = userRoleModel.organizationMapping ?? "";
      const orgIds = orgMapping.split(",").map(Number);

      const filteredOrgs = organizations.filter((org) =>
        orgIds.includes(org.orgId)
      );
      const orgData = filteredOrgs.map((org) => ({
        orgId: org.orgId,
        orgName: org.orgName,
      }));
      setOrgNames(orgData);
    } catch (err) {
      console.error("Error:", err);
    }
    setToggle(!toggle);
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell data-testid="userName" sx={{ width: "10%" }}>
          {props.row.userName}
        </TableCell>
        <TableCell data-testid="userEmail" sx={{ width: "8%" }}>
          {props.row.userEmail}
        </TableCell>
        <TableCell sx={{ width: "8%", border: "none" }} data-testid="company">
          {props.row.company}
        </TableCell>
        <TableCell data-testid="roleId" sx={{ width: "8%" }}>
          {getRoleNameById(props.row.roleId)}
        </TableCell>
        <TableCell sx={{ width: "12%" }}>
          <span title="Edit User" style={{ cursor: "pointer" }}>
            <EditIcon
              style={{
                cursor: "pointer",
                color: "#e4610f",
                marginRight: "8px",
              }}
              data-testid="edituser"
              onClick={props.handleEditClick}
            />
          </span>
          <span title="Delete User" style={{ cursor: "pointer" }}>
            <DeleteOutlineIcon
              data-testid="deleteIconTestId"
              onClick={() =>
                handleDeleteClick(
                  props.row.userRoleId ?? "",
                  props.row.userName ?? ""
                )
              }
              style={{
                color: "#e4610f",
                cursor: "pointer",
                marginRight: "8px",
              }}
            />
          </span>

          {isSuperAdmin && (
            <span title="Assign Organization" style={{ cursor: "pointer" }}>
              <AddToPhotosIcon
                data-testid="assignOrganization"
                style={{
                  color: "#e4610f",
                  cursor: "pointer",
                  marginRight: "8px",
                }}
                onClick={() =>
                  handleAssignOrganizationClick(props.row.userRoleId ?? "")
                }
              />
            </span>
          )}
          <span
            title="Expand Assign Organization"
            style={{ cursor: "pointer" }}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleExpand(props.row)}
            >
              {toggle ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={toggle} timeout="auto" unmountOnExit>
            {toggle && (
              <Box
                sx={{
                  m: 2,
                  border: "1px solid #D1DCE9",
                  borderRadius: "4px",
                }}
              >
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          border: "1px solid #D1DCE9",
                          padding: "8px",
                        }}
                      >
                        Assigned Organizations
                      </th>
                      <th
                        style={{
                          border: "1px solid #D1DCE9",
                          padding: "8px",
                        }}
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {orgNames.map((org, index) => (
                      <tr key={org.orgId}>
                        <td
                          style={{
                            border: "1px solid #D1DCE9",
                            padding: "8px",
                          }}
                        >
                          {org.orgName}
                        </td>
                        <td
                          style={{
                            border: "1px solid #D1DCE9",
                            padding: "8px",
                          }}
                        >
                          <span
                            title="Delete Assigned Organization"
                            style={{ cursor: "pointer" }}
                          >
                            <DeleteOutlineIcon
                              data-testid="deleteOrgIconTestId"
                              onClick={() =>
                                handleOrgDeleteClick(
                                  props.row.userRoleId ?? "",
                                  org.orgName,
                                  org.orgId
                                )
                              }
                              style={{
                                color: "#e4610f",
                                marginRight: "8px",
                              }}
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
      <SnackBar
        show={success.show}
        message={success.message}
        severity={success.severity}
        onclose={() =>
          handleCloseSnackBar({ show: false, severity: "info", message: "" })
        }
      />
      <AlertDialog
        open={openModal}
        openDialog={openDialog}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        organizationName={userName}
        confirmationMessage={ConfirmationMessages.UserConfirmation}
      />
      <AlertDialog
        open={openModalForOrg}
        openDialog={openDialogForOrg}
        handleClose={handleCloseForOrg}
        handleConfirm={handleOrgConfirm}
        organizationName={userName}
        confirmationMessage={ConfirmationMessages.AssignOrgConfirmation}
      />
      <FormDialog
        dialogOpen={dialogOpen}
        hideDialogHandler={hideDialogHandler}
        dialogTitle="Assign Organization"
        isFullScreenAllowed={true}
        maxWidth="sm"
        childComponent={
          <AssignOrganization
            userRoleModel={props.row}
            hideDialogHandler={hideDialogHandler}
            getUserRoles={props.getUserRoles}
            handleCloseSnackBar={handleCloseSnackBar}
            organizations={props.organizations}
          ></AssignOrganization>
        }
      ></FormDialog>
    </React.Fragment>
  );
}

export default Row;

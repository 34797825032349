import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import Spinner from "components/Spinner";
import {
  MasterPropertyCategories,
  MasterPropertyType,
} from "constants/constant";
import masterPropertyApi from "middlewares/MasterProperty/masterPropertyListApi";
import {
  _IsOrganizationExist,
  _addOrganization,
  _getOrganization,
  _updateOrganization,
} from "middlewares/OrganizationApi/organization";
import { OrganizationModel } from "object-models/organization-model";
import { MasterPropertyModel } from "object-models/shared/master-property.model";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import numericValidator from "services/numericValidator";
import CreateOrganizationDesign from "./CreateUpdateOrganizationDesign";
import NotificationHelper from "services/notification-helper";
import { useAppState } from "context/appState.context";
import { BusinessUnitModel } from "object-models/BusinessUnitModel";
interface CreateOrganizationProps {
  closeDialog: () => void;
  isEdit?: boolean;
}

/**
 * Component for creating or updating an organization.
 */

export default function CreateUpdateOrganization(
  props:Readonly<CreateOrganizationProps>
) {
  const defaultOrganization: OrganizationModel = {
    orgId: 0,
    orgName: "",
    industry: undefined,
    currency: "",
    annualRevenue: "",
    noOfEmployees: "",
    isActive: true,
    scope1GHGEmission: undefined,
    scope2GHGEmission: undefined,
    scope3GHGEmission: undefined,
    purchasedGoodsAndServices: undefined,
    capitalGoods: undefined,
    fuelandEnergyRelatedActivities: undefined,
    upstreamTransportationAndDistribution: undefined,
    wasteGeneratedinOperations: undefined,
    businessTravel: undefined,
    employeeCommuting: undefined,
    upstreamLeasedAssets: undefined,
    downstreamTransportationAndDistribution: undefined,
    processingofSoldProducts: undefined,
    useofSoldProducts: undefined,
    endofLifeTreatmentofSoldProducts: undefined,
    downstreamLeasedAssets: undefined,
    franchises: undefined,
    investments: undefined,
    otherUpstream: undefined,
    otherDownstream: undefined,
    businessUnits: [],
  };

  const businessUnitList = ["1", "2", "3", "4", "5"];

  const { OrganizationId } = useParams();
  const { setNotificationConfig } = useAppState();
  const Navigate = useNavigate();

  const [draftOrganization, setDraftOrganization] =
    useState(defaultOrganization);

  const [organizationName, setOrganizationName] = useState(
    defaultOrganization.orgName
  );

  const [industryList, setIndustryList] = useState(
    [] as Array<MasterPropertyModel>
  );
  const [annualRevenueRangeList, setAnnualRevenueRangeList] = useState(
    [] as Array<MasterPropertyModel>
  );
  const [loading, setLoading] = useState(false);
  const [noOfEmployeesRangeList, setNoOfEmployeesRangeList] = useState(
    [] as Array<MasterPropertyModel>
  );

   /**
   * Handles changes in form fields.
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent} event - The change event object.
   */
  function fieldChangeHandler(
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) {
    const numericCheckColumns = [
      "scope1GHGEmission",
      "scope2GHGEmission",
      "scope3GHGEmission",
      "purchasedGoodsAndServices",
      "capitalGoods",
      "fuelandEnergyRelatedActivities",
      "upstreamTransportationAndDistribution",
      "wasteGeneratedinOperations",
      "businessTravel",
      "employeeCommuting",
      "upstreamLeasedAssets",
      "downstreamTransportationAndDistribution",
      "processingofSoldProducts",
      "useofSoldProducts",
      "endofLifeTreatmentofSoldProducts",
      "downstreamLeasedAssets",
      "franchises",
      "investments",
      "otherUpstream",
      "otherDownstream",
    ];

    setDraftOrganization({
      ...draftOrganization,
      [event.target.name]: numericCheckColumns.includes(event.target.name)
        ? numericValidator
            .allowNumericValue(event.target.value)
            .toLocaleString("en-US")
        : event.target.value,
    });
  }

   /**
   * Handles the addition of business units.
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent} event - The change event object.
   */
  function AddBusinessUnit(
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) {
    let noOfBusinessUnit = parseInt(event.target.value);
    let businessUnit = draftOrganization.businessUnits;
    let currentCount = businessUnit?.length ?? 0;
    if (currentCount > noOfBusinessUnit) {
      businessUnit?.splice(noOfBusinessUnit, currentCount);
    } else {
      for (let i = currentCount; i < noOfBusinessUnit; i++) {
        let businessUnitObject: BusinessUnitModel = {
          id: 0,
          orgID: draftOrganization.orgId,
          name: "",
        };
        businessUnit?.push(businessUnitObject);
      }
    }
    setDraftOrganization({
      ...draftOrganization,
      ["businessUnits"]: businessUnit,
    });
  }

  /**
   * Updates the business unit at the specified index.
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent} event - The change event object.
   * @param {number} index - The index of the business unit to update.
   */
  function updateBusinessUnit(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent,
    index: number
  ) {
    if (!draftOrganization?.businessUnits) return;
  
    const updatedBusinessUnits = [...draftOrganization.businessUnits];
    updatedBusinessUnits[index] = {
      ...updatedBusinessUnits[index],
      name: event.target.value,
    };
    setDraftOrganization({
      ...draftOrganization,
      businessUnits: updatedBusinessUnits,
    });
  }

  useEffect(() => {
    const scope3GHGEmissionFields = [
      "purchasedGoodsAndServices",
      "capitalGoods",
      "fuelandEnergyRelatedActivities",
      "upstreamTransportationAndDistribution",
      "wasteGeneratedinOperations",
      "businessTravel",
      "employeeCommuting",
      "upstreamLeasedAssets",
      "downstreamTransportationAndDistribution",
      "processingofSoldProducts",
      "useofSoldProducts",
      "endofLifeTreatmentofSoldProducts",
      "downstreamLeasedAssets",
      "franchises",
      "investments",
      "otherUpstream",
      "otherDownstream",
    ];
    const sum = scope3GHGEmissionFields.reduce((total, propName) => {
      return parseFloat(
        (
          total +
          (draftOrganization[propName as keyof OrganizationModel]
            ?.toString()
            .trim() ?? "".length > 0
            ? parseFloat(
                draftOrganization[
                  propName as keyof OrganizationModel
                ]?.toString() ?? "0"
              )
            : 0)
        ).toFixed(2)
      );
    }, 0);
    setDraftOrganization({
      ...draftOrganization,
      scope3GHGEmission: sum > 0.0 ? sum : "",
    });
  }, [
    draftOrganization.purchasedGoodsAndServices,
    draftOrganization.capitalGoods,
    draftOrganization.fuelandEnergyRelatedActivities,
    draftOrganization.upstreamTransportationAndDistribution,
    draftOrganization.wasteGeneratedinOperations,
    draftOrganization.businessTravel,
    draftOrganization.employeeCommuting,
    draftOrganization.upstreamLeasedAssets,
    draftOrganization.downstreamTransportationAndDistribution,
    draftOrganization.processingofSoldProducts,
    draftOrganization.useofSoldProducts,
    draftOrganization.endofLifeTreatmentofSoldProducts,
    draftOrganization.downstreamLeasedAssets,
    draftOrganization.franchises,
    draftOrganization.investments,
    draftOrganization.otherUpstream,
    draftOrganization.otherDownstream,
  ]);

  /**
   * Fetches organization details if the component is in edit mode.
   */
  function getOrganizationDetails() {
    setLoading(true);
    if (OrganizationId !== undefined && OrganizationId?.length > 0) {
      _getOrganization(+OrganizationId)
        .then((res) => {
          setDraftOrganization(res);
          setOrganizationName(res.orgName);
          setLoading(false);
        })
        .catch((err) => {});
    }
  }

  /**
   * Checks if the organization with the given name already exists.
   * @param {string} orgname - The name of the organization to check.
   * @returns {Promise<boolean>} - Returns true if the organization exists, otherwise false.
   */
  async function isOrganizationExist(orgname: string) {
    if (organizationName === draftOrganization.orgName) {
      return false;
    }
    if (orgname !== "") {
      try {
        const exist = await _IsOrganizationExist(orgname);
        return exist;
      } catch (err) {
        console.error("Error checking organization existence:", err);
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * Creates a new organization.
   */
  function create() {
    setLoading(true);
    _addOrganization(draftOrganization)
      .then((res) => {
        if (res) {
          setLoading(false);
          setOrganizationName(res.orgName);
          props.closeDialog();
          Navigate(`/Organization/${res.orgId}/Organization-Details`);
          const notification = NotificationHelper.successNotification(
            `Organization ${res.orgName} created successfully`
          );
          setNotificationConfig(notification);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  /**
   * Updates the existing organization.
   */
  function update() {
    setLoading(true);
    _updateOrganization(draftOrganization)
      .then((res) => {
        if (res) {
          setLoading(false);
          setOrganizationName(draftOrganization.orgName);
          props.closeDialog();
          const notification = NotificationHelper.successNotification(
            `Organization ${res.orgName} saved successfully`
          );
          setNotificationConfig(notification);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  /**
   * Saves the organization (either creates or updates based on the mode).
   */
  function onSave() {
    if (props.isEdit !== undefined && props.isEdit === true) {
      update();
    } else {
      create();
    }
  }

  /**
   * Fetches static lists required for creating an organization.
   */
  async function getCreateOrganizationStaticLists() {
    setLoading(true);
    masterPropertyApi
      .getMasterPropertyListByCategory(MasterPropertyCategories.Organization)
      .then((res) => {
        if (res.length > 0) {
          setIndustryList(
            res.filter(
              (prop) => prop.type === MasterPropertyType.Organization.Industry
            )
          );

          setNoOfEmployeesRangeList(
            res.filter(
              (prop) =>
                prop.type === MasterPropertyType.Organization.NoOfEmployeesRange
            )
          );
          setAnnualRevenueRangeList(
            res.filter(
              (prop) =>
                prop.type === MasterPropertyType.Organization.AnnualRevenueRange
            )
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("error");
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  useEffect(() => {
    if (props.isEdit !== undefined && props.isEdit === true) {
      getOrganizationDetails();
    }
  }, []);
  useEffect(() => {
    getCreateOrganizationStaticLists();
  }, []);

  return (
    <React.Fragment>
      <Spinner size={80} data-testid="spinner" open={loading} />
      <CreateOrganizationDesign
        organization={draftOrganization}
        fieldChangeHandler={fieldChangeHandler}
        onSave={onSave}
        industryList={industryList}
        NoOfEmployeesRangeList={noOfEmployeesRangeList}
        annualRevenueRangeList={annualRevenueRangeList}
        isOrganizationExist={isOrganizationExist}
        businessUnitList={businessUnitList}
        AddBusinessUnit={AddBusinessUnit}
        UpdateBusinessUnit={updateBusinessUnit}
      ></CreateOrganizationDesign>
    </React.Fragment>
  );
}

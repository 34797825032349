import React, { useState, useCallback, useEffect } from "react";
import { AccountCircle } from "@mui/icons-material";
import { Avatar, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { AccountInfo } from "@azure/msal-browser";

interface LoggedInAvatarMenuProps {
    signOut: () => void; 
    account:AccountInfo | null; 
  }
export const LoggedInAvatarMenu:React.FC<LoggedInAvatarMenuProps> = ({signOut, account}) => {

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [email, setEmail] = useState("");
  const [avatarText, setAvatarText] = useState("");
  const handleLogout = useCallback(() => {
    setAnchorEl(null);
    signOut();
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  function stringAvatar(name: string) {
    const text = `${name.split(".")[0][0].toUpperCase()}${name
      .split(".")[1][0]
      .toUpperCase()}`;

    setAvatarText(text);
  }

  useEffect(() => {
    if(account!=undefined){
        const email = account.idTokenClaims?.email as string;
        setEmail(email);
        stringAvatar(email);
    }    
  }, []);

  return (
    <div>
      <IconButton
        id="menuButton"
        data-testid="menuButton"
        onClick={handleOpen}
        color="inherit"
      >
        {email === "" ? (
          <AccountCircle fontSize="large" sx={{ color: "#78909c" }} />
        ) : (
          <Avatar
            sx={{
              bgcolor: "#e4610f",
              width: 28,
              height: 28,
              mt: 0.6,
            }}
          >
            <Typography variant="caption">{avatarText}</Typography>
          </Avatar>
        )}
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={anchorEl !== null}
        onClose={handleClose}
      >
        <MenuItem
          id="useremail"
          data-testid="useremail"
          sx={{ pointerEvents: "none", fontSize: 14, color: "#a39c9b" }}
        >
          {email}
        </MenuItem>       

        <MenuItem
          id="logoutButton"
          data-testid="logoutButton"
          onClick={handleLogout}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};
export default LoggedInAvatarMenu;
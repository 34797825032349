import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import List from "@mui/material/List";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Stack, useTheme, IconButton } from "@mui/material";
import "./data/ProjectLayout.css";
import SimpleMenu from "./data/SimpleMenu.list";
import { useAppState } from "context/appState.context";
import ListItem from "./ListItem";
import ChildItems from "./ChildItems";

/**
 * PageNavigation component handles the navigation menu of the page.
 * It uses Material-UI components and supports nested menu items with expand/collapse functionality.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Boolean indicating whether the navigation menu is open or not.
 * @returns {JSX.Element} The rendered component.
 */
const PageNavigation = ({ open }: { open: boolean }) => {
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const secondary = "#757474";
  const routeParams = useParams();
  const currentMenuItem = SimpleMenu().filter(
    (sm) => sm.link === routeParams["*"]
  )[0]?.label;

  useEffect(() => {
    localStorage.setItem(
      "HighlightsMenu",
      currentMenuItem ?? "Organization Details"
    );
    localStorage.setItem("Highlights", "");
  }, []);

  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({
    Dashboards: false,
    Settings: false,
  });

  const { userRole } = useAppState();

  const toggleMenu = (menuName: string) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [menuName]: !prevState[menuName],
    }));
  };
  return (
    <List data-testid={"navTabs"}>
      {SimpleMenu().map((item: any) => {
        const isHighlighted =
          item.label === localStorage.getItem("HighlightsMenu");
        if (
          item.allowedRoles.includes(
            userRole || sessionStorage.getItem("userRole")
          )
        ) {
          return (
            <React.Fragment key={item.id}>
              <Stack direction={"row"}>
                <ListItem
                  item={item}
                  open={open}
                  isHighlighted={isHighlighted}
                  primary={primary}
                  secondary={secondary}
                />
                {item.children && item.children.length > 0 && open && (
                  <IconButton onClick={() => toggleMenu(item.label)}>
                    {!openMenus[item.label] ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ExpandLessIcon />
                    )}
                  </IconButton>
                )}
              </Stack>
              {item.children && item.children.length > 0 && (
                <ChildItems
                  open={open}
                  dashboardsTabOpen={openMenus[item.label]}
                  primary={primary}
                  secondary={secondary}
                >
                  {item.children}
                </ChildItems>
              )}
            </React.Fragment>
          );
        }
        return null;
      })}
    </List>
  );
};

export default PageNavigation;

import { Scope3GHGReductionModel } from "./Scope3GHGReduction";

// Base years array
export const baseYears = Array.from({ length: 16 }, (_, index) => 2015 + index);

// Target years array
export const targetYears = Array.from({ length: 27 }, (_, index) => 2024 + index);

// Scopes covered options array
export const scopesCoveredOptions = [
  { id: 1, label: "Scope 1", value: "01" },
  { id: 2, label: "Scope 2", value: "02" },
  { id: 3, label: "Scope 3", value: "03" },
];

export const initialCategories: Scope3GHGReductionModel[] = [
  {
    nzdmId: 1,
    id:0,
    catagory: 1,
    catagoryName: "Purchased Goods & Services",
    reduction: "0",
    selected: false,
  },
  {
    nzdmId: 2,
    id:0,
    catagory: 2,
    catagoryName: "Capital Goods",
    reduction: "0",
    selected: false,
  },
  {
    nzdmId: 3,
    id:0,
    catagory: 3,
    catagoryName: "Fuel- and Energy-Related Activities",
    reduction: "0",
    selected: false,
  },
  {
    nzdmId: 4,
    id:0,
    catagory: 4,
    catagoryName: "Upstream Transportation & Distribution",
    reduction: "0",
    selected: false,
  },
  {
    nzdmId: 5,
    id:0,
    catagory: 5,
    catagoryName: "Waste Generated in Operations",
    reduction: "0",
    selected: false,
  },
  {
    nzdmId: 6,
    id:0,
    catagory: 6,
    catagoryName: "Business Travel",
    reduction: "0",
    selected: false,
  },
  {
    nzdmId: 7,
    id:0,
    catagory: 7,
    catagoryName: "Employee Commuting",
    reduction: "0",
    selected: false,
  },
  {
    nzdmId: 8,
    id:0,
    catagory: 8,
    catagoryName: "Upstream Leased Assets",
    reduction: "0",
    selected: false,
  },
  {
    nzdmId: 9,
    id:0,
    catagory: 9,
    catagoryName: "Downstream Transportation & Distribution",
    reduction: "0",
    selected: false,
  },
  {
    nzdmId: 10,
    id:0,
    catagory: 10,
    catagoryName: "Processing of Sold Products",
    reduction: "0",
    selected: false,
  },
  {
    nzdmId: 11,
    id:0,
    catagory: 11,
    catagoryName: "Use of Sold Products",
    reduction: "0",
    selected: false,
  },
  {
    nzdmId: 12,
    id:0,
    catagory: 12,
    catagoryName: "End-of-Life Treatment of Sold Products",
    reduction: "0",
    selected: false,
  },
  {
    nzdmId: 13,
    id:0,
    catagory: 13,
    catagoryName: "Downstream Leased Assets",
    reduction: "0",
    selected: false,
  },
  {
    nzdmId: 14,
    id:0,
    catagory: 14,
    catagoryName: "Franchises",
    reduction: "0",
    selected: false,
  },
  {
    nzdmId: 15,
    id:0,
    catagory: 15,
    catagoryName: "Investments",
    reduction: "0",
    selected: false,
  },
  {
    nzdmId: 16,
    id:0,
    catagory: 16,
    catagoryName: "Other Upstream",
    reduction: "0",
    selected: false,
  },
  {
    nzdmId: 17,
    id:0,
    catagory: 17,
    catagoryName: "Other Downstream",
    reduction: "0",
    selected: false,
  },
];



